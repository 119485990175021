import React from 'react'
import { useWindowSize } from "api/commonConstants";
function Tally({masterConfig , showExitTally , showResultTally , stateName}) {

    const size = useWindowSize();
  return (
  
        <>
          { showExitTally != false && masterConfig?.MaharashtraExittally && (
            <>
              {size.width < 768 ? (
                <div className="result-page">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा एग्ज़िट पोल
                  </h2>
                  <div>
                    <iframe
                      src={!stateName ?  `${masterConfig?.MaharashtraExittally_URL}`   :`${masterConfig?.MaharashtraExittally_URL}&state_name=${stateName}`}
                      width="100%"
                      height={
                        masterConfig?.Maharashtra_Exitpolltally_height_mobile
                      }
                    ></iframe>
                  </div>
                </div>
              ) : (
                <div className="result-page">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा एग्ज़िट पोल
                  </h2>
                  <div>
                    <iframe
                    src={!stateName ?  `${masterConfig?.MaharashtraExittally_URL}`   :`${masterConfig?.MaharashtraExittally_URL}&state_name=${stateName}`}

                      width="100%"
                      height={
                        masterConfig?.Maharashtra_Exitpolltally_height_web
                      }
                    ></iframe>
                  </div>
                </div>
              )}
            </>
          )}
          {showResultTally != false && masterConfig?.MaharashtraResulttally && (
            <>
              {size.width < 768 ? (
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={!stateName ? `${masterConfig?.MaharashtraResulttally_URL}` :`${masterConfig?.MaharashtraResulttally_URL}&state_name=${stateName}`}
                      width="100%"
                      height={
                        masterConfig?.Maharashtra_Resulttally_height_mobile
                      }
                    ></iframe>
                  </div>
                </div>
              ) : (
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    विधानसभा रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={!stateName ? `${masterConfig?.MaharashtraResulttally_URL}` :`${masterConfig?.MaharashtraResulttally_URL}&state_name=${stateName}`}
                      width="100%"
                      height={masterConfig?.Maharashtra_Resulttally_height_web}
                    ></iframe>
                  </div>
                </div>
              )}
            </>
          )}
          {masterConfig?.BypollResulttally && (
            <>
              {size.width < 768 ? (
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    उपचुनाव रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={masterConfig?.BypollResulttally_URL}
                      width="100%"
                      height={masterConfig?.Bypoll_Resulttally_height_mobile}
                    ></iframe>
                  </div>
                </div>
              ) : (
                <div className="result-page-big">
                  <h2
                    style={{
                      fontSize: "27px",
                      color: "#D9368B",
                      fontWeight: "800",
                    }}
                  >
                    उपचुनाव रिजल्ट
                  </h2>
                  <div>
                    <iframe
                      src={masterConfig?.BypollResulttally_URL}
                      width="100%"
                      height={masterConfig?.Bypoll_Resulttally_height_web}
                    ></iframe>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}


export default Tally
